import React from 'react';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { feeGrantAllowanceClaim } from '../../actions/account';
import { fetchAllowances } from '../../actions/account/BCDetails';
import moment from 'moment';
import DotsLoading from '../../components/DotsLoading';
import { config } from '../../config';

const ClaimFeeGrantCard = (props) => {
    const onClick = () => {
        if (props.address) {
            props.feeGrantAllowanceClaim((data) => {
                if (data) {
                    props.fetchAllowances(props.address);
                }
            });
        }
    };

    const granterInfo = {};
    if (props.allowances && props.allowances.length) {
        props.allowances.map((val) => {
            if (val && val.allowance && val.allowance.spend_limit && val.allowance.spend_limit.length) {
                const amount = val.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                    val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                if (amount && amount.amount) {
                    granterInfo.granter = val.granter;
                    granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                    granterInfo.expiration = val.allowance.expiration;
                }
            } else if (val && val.allowance && val.allowance.allowed_messages &&
                val.allowance.allowed_messages.length) {
                if (val && val.allowance && val.allowance.allowance &&
                    val.allowance.allowance.spend_limit && val.allowance.allowance.spend_limit.length) {
                    const amount = val.allowance.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                        val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                    if (amount && amount.amount) {
                        granterInfo.granter = val.granter;
                        granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                        granterInfo.expiration = val && val.allowance && val.allowance.allowance &&
                            val.allowance.allowance.expiration;
                    }
                }
            }

            return null;
        });
    }

    const type = '/cosmos.bank.v1beta1.MsgSend';
    let disable = true;
    if (props.allowances && props.allowances.length) {
        props.allowances.map((val) => {
            if (val && val.allowance && val.allowance.allowed_messages &&
                type && val.allowance.allowed_messages.indexOf(type) === -1) {
                disable = false;
            }

            return null;
        });
    }

    const inProgress = props.inProgress || props.allowancesInProgress;

    return (
        <div className="claim_fee_grant_card">
            <div className="header">
                <h2>{variables[props.lang]['claim_fee_grant']}</h2>
            </div>
            <p className="content1">{variables[props.lang]['claim_fee_grant_content']}</p>
            <p className="content2">
                <span>{variables[props.lang].flix}</span>
                {variables[props.lang]['claim_fee_grant_content1']}
            </p>
            {inProgress
                ? <Button disabled={true} variant="contained">
                    {variables[props.lang].processing + '...'}
                </Button>
                : granterInfo && granterInfo.amount && (granterInfo.amount > 0) && disable &&
                granterInfo.expiration && moment().diff(granterInfo && granterInfo.expiration) < 0
                    ? <Button disabled={true} variant="contained">
                        {variables[props.lang]['allowance_received']}
                    </Button>
                    : <Button
                        disabled={!props.address}
                        variant="contained"
                        onClick={onClick}>
                        {variables[props.lang]['request_fee_grant']}
                    </Button>}
            {inProgress
                ? null
                : (granterInfo && granterInfo.expiration) &&
                moment().diff(granterInfo && granterInfo.expiration) > 0
                    ? <div className="allowance_amount">
                        <div>
                            <p>{variables[props.lang]['fee_grant_expired']}</p>
                        </div>
                    </div>
                    : granterInfo && granterInfo.amount && <div className="allowance_amount">
                        <div>
                            <span>{variables[props.lang].allowance}:</span>
                            {props.allowancesInProgress
                                ? <DotsLoading/>
                                : <p>{granterInfo && granterInfo.amount} {config.COIN_DENOM}</p>}
                        </div>
                        <div>
                            <span>{variables[props.lang].expiry}:</span>
                            {props.allowancesInProgress
                                ? <DotsLoading/>
                                : <p>{moment(granterInfo && granterInfo.expiration).endOf('day').fromNow()}</p>}
                        </div>
                    </div>}
        </div>
    );
};

ClaimFeeGrantCard.propTypes = {
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    feeGrantAllowanceClaim: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        inProgress: state.account.feeGrant.inProgress,
        lang: state.language,
    };
};

const actionToProps = {
    fetchAllowances,
    feeGrantAllowanceClaim,
};

export default connect(stateToProps, actionToProps)(ClaimFeeGrantCard);
