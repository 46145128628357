import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import variables from '../../utils/variables';
import { Button, Tooltip } from '@mui/material';
import PricePerNFTTextField from './PricePerNFTTextField';
import { showMintNFTDialog } from '../../actions/home';
import ClassNames from 'classnames';
import NetworkImages from '../../components/NetworkImages';
import DotsLoading from '../../components/DotsLoading';
import moment from 'moment';
import Counter from './Counter';

const MintNFTSCard = (props) => {
    const [active, setActive] = useState(1);

    const denom = props.project && props.project.price && props.project.price.denom;
    const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
        props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
    const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;

    let balance = props.balance && props.balance.length && props.balance.find((val) => val.denom === denom);
    balance = balance && balance.amount && balance.amount / (10 ** decimals);

    const price = (props.pricePerNFTValue) * active && ((props.pricePerNFTValue) * active).toFixed(2);
    const data = props.saleType && props.saleType === 'pre'
        ? props.project['pre_sale'] : props.project;
    const startTime = data && data['start_time'] &&
        moment().diff(data['start_time']) > 0;
    const endTime = data && data['end_time'] &&
        moment().diff(data['end_time']) < -1000;
    const availableCount = props.project && props.project['available_units'];
    const disable = !(props.pricePerNFTValue > 0) || balance < price || availableCount <= 0 ||
        !props.saleInProgress || !props.mintLimit || props.mintLimit <= 0;
    const object = {
        price: props.pricePerNFTValue,
        count: active,
        total_price: price,
    };
    const statusInProgress = props.projectInProgress || props.inProgress;

    return (
        <div className="mint_nft_card">
            <div className="header">
                <h2>{variables[props.lang]['mint_nft']}</h2>
                {statusInProgress
                    ? <DotsLoading/>
                    : <div>
                        {data && !data['pre_sale'] &&
                        <span className="whitelist_drop">{variables[props.lang]['whitelist_drop']}</span>}
                        {props.saleInProgress || (startTime && endTime)
                            ? <span className="status">{variables[props.lang].active}</span>
                            : <span className="status in_active">{variables[props.lang]['in_active']}</span>}
                    </div>}
            </div>
            <p className="content">{variables[props.lang]['mint_nft_content']}</p>
            <div className="section">
                <p className="content_header">{variables[props.lang]['price_per_nft']}</p>
                <PricePerNFTTextField/>
            </div>
            <div className="section">
                <div className="left_section">
                    <p className="content_header">{variables[props.lang]['select_quantity']}</p>
                    {props.address && props.mintLimit &&
                    <p className="quota">
                        {data && data['max_units_per_address'] &&
                        props.mintLimit && (data['max_units_per_address'] - props.mintLimit)} of {data &&
                    data['max_units_per_address']} minted</p>}
                </div>
                <div className="quantity_number">
                    {[...Array(8)].map((u, i) =>
                        <Button
                            key={i}
                            className={ClassNames('default_quantity', active === (i + 1)
                                ? 'active_quantity' : '')}
                            disabled={props.mintLimit && props.mintLimit !== ''
                                ? (i + 1) > props.mintLimit
                                : true}
                            onClick={() => setActive(i + 1)}>{i + 1}</Button>,
                    )}
                </div>
            </div>
            <div className="section">
                <p className="content_header">{variables[props.lang].total}</p>
                <div className="total_cal">
                    <div>
                        <NetworkImages
                            alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                            name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                        <span>{props.pricePerNFTValue}</span>
                    </div>
                    <span>x</span>
                    <div>
                        <p>{active}</p>
                        <span>Qty</span>
                    </div>
                    <span>=</span>
                    <div>
                        <NetworkImages
                            alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                            name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                        <p className="total_amount">{price}</p>
                        <p>{ibcToken && ibcToken.network && ibcToken.network.display_denom}</p>
                    </div>
                </div>
            </div>
            <Tooltip
                arrow
                title={props.mintLimit && props.mintLimit <= 0 && (props.saleInProgress || (startTime && endTime))
                    ? `Minted your quota of ${data['max_units_per_address']} NFTs. Try to get them via the secondary on OmniFlix.market`
                    : ''}>
                <span>
                    <Button
                        className={disable ? 'mint_button disabled_mint' : 'mint_button'}
                        disabled={disable}
                        onClick={() => props.showMintNFTDialog(object)}>
                        {availableCount <= 0 && props.project
                            ? variables[props.lang]['sold_out']
                            : variables[props.lang].mint}
                    </Button>
                </span>
            </Tooltip>
            {props.projectInProgress
                ? <DotsLoading/>
                : props.project && props.project['available_units']
                    ? <div className="mint_info">
                        <p className="mint_left">{props.project['available_units']} {' '}{variables[props.lang].left}</p>
                        <Counter
                            endTime={data && data['end_time']}
                            project={props.project}
                            startTime={data && data['start_time']}/>
                    </div>
                    : null}
        </div>
    );
};

MintNFTSCard.propTypes = {
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    mintLimit: PropTypes.any.isRequired,
    pricePerNFTValue: PropTypes.any.isRequired,
    project: PropTypes.object.isRequired,
    projectInProgress: PropTypes.bool.isRequired,
    saleInProgress: PropTypes.bool.isRequired,
    saleType: PropTypes.string.isRequired,
    showMintNFTDialog: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        balance: state.account.bc.balance.value,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        lang: state.language,
        mintLimit: state.mint.mintLimit.value,
        pricePerNFTValue: state.home.pricePerNFTValue,
        project: state.mint.project.value,
        projectInProgress: state.mint.project.inProgress,
        saleType: state.home.saleType,
        saleInProgress: state.home.saleInProgress,
    };
};

const actionToProps = {
    showMintNFTDialog,
};

export default connect(stateToProps, actionToProps)(MintNFTSCard);
