import React from 'react';
import Banner from '../../assets/banner.png';

const Section1 = () => {
    return (
        <div className="section1">
            <img alt="banner" src={Banner}/>
        </div>
    );
};

export default Section1;
